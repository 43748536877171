import styled from 'styled-components';
import {ErrorPage} from 'components/ui/page/ErrorPage';
import {Logo} from 'components/ui/graphics/Logo';
import Link from 'next/link';
import {LOGIN_URL} from 'components/util/routeConstants';

const ErrorPageStyles = styled.div`
    .title {
        margin-bottom: 50px;
    }
    .description {
        margin-bottom: 50px;
    }
    .notifications-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        top -205px;
    }
`;

const Custom404Page = () => (
    <ErrorPage title="Login" pageId="login">
        <ErrorPageStyles>
            <Logo />
            <h1 className="title">404 - Page Not Found</h1>
            <p>The page you have requested is not available.</p>
            <p>
                <Link href="/">Return Home</Link> |{' '}
                <Link href={LOGIN_URL}>Login</Link>
            </p>
        </ErrorPageStyles>
    </ErrorPage>
);

export default Custom404Page;
